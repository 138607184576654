header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
  }
  .sidebar {
    position: absolute;
    top: 70px;
    left: -30vw;
    height: 100%;
    width: 15vw;
    transition: left 0.3s ease-in-out;
    background-color: lightgray;
  }
  .sidebar-toggle {
    position: absolute;
    top: 20%;
    right: -60px;
    height: 60px;
    width: 60px;
    z-index: 1;
  }
  .content {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 100%;
    transition: left 0.3s ease-in-out;
    background-color: lightgray;
  }
  .sidebar.open {
    left: 0;
  }
  .content.open {
    left: 18vw;
  }
  